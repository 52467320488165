import React from "react";
import { BsBug } from "react-icons/bs";
import { FaHouseChimney } from "react-icons/fa6";
import { BsPersonLinesFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div>
      <div className="page-not-found pt-5">
        <div className="bg-light shadow">
          <h2>
            4<BsBug />4
          </h2>
          <h3 className="mt-4">Opps! Page Not Found</h3>
          <div className="mt-5">
            <Link to="/" className="btn m-2 btn-primary">
              <FaHouseChimney /> Back Home
            </Link>
            <Link to="mailto:screenalley@gmail.com" className="btn m-2 m-md-0 btn-success">
              <BsPersonLinesFill /> Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
