import axios from "axios";

const Instance = axios.create({
  baseURL: "https://api.themoviedb.org/3",
});

// Request interceptor - Set Authorization header
Instance.interceptors.request.use(
  function (config) {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    };
    config.headers["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI2Y2M4MTVjYjNkYjhkMzFhZTM1MjQ3ZGRmZmE5YTA1NiIsInN1YiI6IjY1NTc1ZTIxZDRmZTA0MDBmZTA2ZmIxNSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.dV7H3Sfazwpjt5Tw5zZ18mhKxDUpSs-8StCCEeWovas`; // Replace with your API key
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor - Handle responses or errors
Instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Instance;
