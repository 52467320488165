import React from 'react';

const Preloader = () => {
  return (
    <div className="preloader">
    <div className="lds-hourglass"></div>
      {/*<div className="spinner-border spinner-primary" role="status">
        <span className="visually-hidden">Loading...</span>
  </div>*/}
    </div>
  );
};

export default Preloader;
