import React from "react";
import Card from "../components/Card/Card";
import { Helmet } from "react-helmet";

const Movies = () => {
  return (
    <>
      <Helmet>
        <title>Movies | ScreenAlley</title>
        <meta
          name="description"
          content="ScreenAlley is a popular database for movies trailer."
        />
      </Helmet>
      <Card />
    </>
  );
};

export default Movies;
