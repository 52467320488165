import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import SingleMovie from "./components/SingleMovieDetail/SingleMovie";
import Footer from "./components/Footer/Footer";
import Movies from "./pages/Movies";

// Import the functions you need from the SDKs you need
import Privcay from "./pages/Privcay";
import Page404 from "./pages/Page404";
import { useEffect, useState } from "react";
import Preloader from "./components/Preloader";

function App() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000);
  }, [])
  
  return (
    <>
    {isLoading && <Preloader />}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/movie" element={<Movies />} />
        <Route path="/movie/:id" element={<SingleMovie />} />
        <Route path="/privacy-policy" element={<Privcay />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
