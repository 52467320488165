import React, { useState, useEffect } from "react";
import axios from "../../axios/axios";
import "./Hero.css";
import { fecthImage } from "../../axios/api";

const Hero = () => {
  const [movies, setMovies] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    axios
      .get("/trending/movie/day?language=en-US")
      .then((response) => {
        setMovies(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const openVideoModal = (videoKey) => {
    setSelectedVideo(videoKey);
    // Open the modal here
    document.getElementById("videoModal").classList.add("show");
  };

  const fetchVideoKey = (movieId) => {
    axios
      .get(`/movie/${movieId}/videos?language=en-US`)
      .then((response) => {
        const videoResults = response.data.results;
        if (videoResults.length > 0) {
          const videoKey = videoResults[0].key; // Assuming the first video in the results
          openVideoModal(videoKey);
        }
      })
      .catch((error) => {
        console.error("Error fetching video data:", error);
      });
  };

  return (
    <div id="carouselExampleCaptions" className="carousel slide">
      {/* Carousel Inner */}
      <div className="carousel-inner">
        {movies.slice(0, 12).map((movie, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <div className="gradient-overlay"></div>
            <img
              src={fecthImage + `/${movie.backdrop_path}`}
              className="d-block w-100"
              alt={`Slide ${index + 1}`}
              style={{height: "640px", objectFit: "cover"}}
            />
            <div
              className="carousel-caption text-start"
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: "100%",
                left: "0",
              }}
            >
              <div className="row carousel-indicators align-items-center">
                <div className="col-12 col-md-6">
                  <h3>{movie.title}</h3>
                  <p className="d-none d-md-block">{movie.overview}</p>
                  <div className="d-none d-md-block">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => fetchVideoKey(movie.id)}
                    >
                      WATCH NOW
                    </button>
                  </div>

                  <div className="d-block d-md-none">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => fetchVideoKey(movie.id)}
                    >
                      WATCH NOW
                    </button>
                  </div>
                </div>
                <div className="col-md-6 d-none d-lg-block">
                  <div className="row">
                    <h3>Today's trending</h3>
                    {movies.slice(0, 12).map((movie, index) => (
                      <div key={index} className="col-md-2">
                        <img
                          src={fecthImage + `/${movie.poster_path}`}
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to={index.toString()}
                          className={index === 0 ? "active w-100" : "w-100"}
                          alt={`Slide ${index + 1}`}
                          style={{ height: "90%" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel Controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>

      {/* Modal for Video */}
      {selectedVideo && (
        <div
          className="modal fade show"
          id="videoModal"
          tabIndex="-1"
          aria-labelledby="videoModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          {/* Backdrop overlay */}
          <div
            className="modal-backdrop fade show"
            style={{ zIndex: -1 }}
          ></div>
          {/* Modal content */}
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div
              className="modal-content"
              style={{ background: "transparent", border: "none" }}
            >
              <div className="modal-header" style={{ border: "none" }}>
                <button
                  type="button"
                  className="btn-close bg-info"
                  aria-label="Close"
                  onClick={() => setSelectedVideo(null)}
                ></button>
              </div>
              <div className="modal-body d-none d-md-block">
                {/* Assuming you want to play YouTube videos */}
                <iframe
                  width="100%"
                  height="600"
                  src={`https://www.youtube.com/embed/${selectedVideo}`}
                  title="Movie Trailer"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="modal-body d-block d-md-none">
                {/* Assuming you want to play YouTube videos */}
                <iframe
                  width="100%"
                  height="300"
                  src={`https://www.youtube.com/embed/${selectedVideo}`}
                  title="Movie Trailer"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
