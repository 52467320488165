import axios from "../../axios/axios";
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { FaRegCirclePlay } from "react-icons/fa6";
import TopRated from "../TopRated/TopRated";
import { fecthImage } from "../../axios/api";
import { Helmet } from "react-helmet";

const SingleMovie = () => {
  const pageRef = useRef();
  const { id } = useParams();
  const [movie, setMovie] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);


  const openVideoModal = (videoKey) => {
    setSelectedVideo(videoKey);
    // Open the modal here
    document.getElementById("videoModal").classList.add("show");
  };

  const fetchVideoKey = (movieId) => {
    axios
      .get(`/movie/${movieId}/videos?language=en-US`)
      .then((response) => {
        const videoResults = response.data.results;
        if (videoResults.length > 0) {
          const videoKey = videoResults[0].key; // Assuming the first video in the results
          openVideoModal(videoKey);
        }
      })
      .catch((error) => {
        console.error("Error fetching video data:", error);
      });
  };

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    axios
      .get(`/movie/${id}?language=en-US`)
      .then((response) => {
        setMovie(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(`/movie/${id}/images`)
      .then((response) => {
        setSelectedImage(response.data.backdrops);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    
  }, [id]);

  return (
    <>
      <Helmet>
        <title>
          {movie.title ? `${movie.title} | ScreenAlley` : "Loading..."}
        </title>
        <meta
          name="description"
          content={`${movie.title} - ${movie.overview}`}
        />
      </Helmet>
      <div ref={pageRef}></div>
      <div
        className="container-fluid mb-5"
        style={{
          backgroundImage: `linear-gradient(to left, rgba(0, 56, 60, 0.3), rgba(0, 56, 60, 0.3)), url(${fecthImage}/${movie.backdrop_path})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container py-5 text-light">
          <div className="row">
            <div className="col-12 col-md-4 position-relative">
              <img
                src={fecthImage + `/${movie.poster_path}`}
                className="w-100"
                alt={`/${movie.poster_path}`}
              />
              <div
                className="position-absolute top-50 start-50 translate-middle"
                style={{ zIndex: 1 }}
              >
                <FaRegCirclePlay
                  onClick={() => fetchVideoKey(movie.id)}
                  style={{ fontSize: "4rem", color: "#fff", cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <h1>{movie.title}</h1>
              <h4>{movie.original_title}</h4>
              <p>{movie.overview}</p>
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <h6>Genres:</h6>
                  {movie && movie.genres && movie.genres.length > 0 ? (
                    <>
                      {movie.genres.map((item, index) => (
                        <p key={index} className="d-inline">
                          <span> {item.name} |</span>
                        </p>
                      ))}
                    </>
                  ) : (
                    <p>No genres available</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <h6>Languages:</h6>
                  {movie &&
                  movie.spoken_languages &&
                  movie.spoken_languages.length > 0 ? (
                    <>
                      {movie.spoken_languages.map((item, index) => (
                        <p key={index} className="d-inline">
                          <span> {item.english_name} |</span>
                        </p>
                      ))}
                    </>
                  ) : (
                    <p>No genres available</p>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <h6>Production country / countries:</h6>
                  {movie &&
                  movie.production_countries &&
                  movie.production_countries.length > 0 ? (
                    <>
                      {movie.production_countries.map((item, index) => (
                        <p key={index} className="d-inline">
                          <span> {item.name} |</span>
                        </p>
                      ))}
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <h6>Release date:</h6>
                  {movie && movie.release_date ? (
                    <>
                      <p>{new Date(movie.release_date).toLocaleDateString()}</p>
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <h6>Vote:</h6>
                  {movie && movie.vote_count ? (
                    <>
                      <span> {movie.vote_count} </span>
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <h6>Status:</h6>
                  {movie && movie.status ? (
                    <>
                      <p>{movie.status}</p>
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <h6>Production companies:</h6>
                  {movie &&
                  movie.production_companies &&
                  movie.production_companies.length > 0 ? (
                    <>
                      {movie.production_companies.map((item, index) => (
                        <span key={index}> {item.name} | </span>
                      ))}
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <h6>Original language:</h6>
                  {movie && movie.original_language ? (
                    <>
                      <p>{movie.original_language}</p>
                    </>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6"></div>

                <div className="col-12 col-md-6">
                  <h6>Homepage:</h6>
                  {movie && movie.homepage ? (
                    <>
                      <Link
                        to={movie.homepage}
                        target="_blank"
                        className="btn btn-dark fw-bold text-light"
                      >
                        Visit homepage
                      </Link>
                    </>
                  ) : (
                    <p className="btn btn-info fw-bold text-light">
                      Not available
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Video */}
        {selectedVideo && (
          <div
            className="modal fade show"
            id="videoModal"
            tabIndex="-1"
            aria-labelledby="videoModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            {/* Backdrop overlay */}
            <div
              className="modal-backdrop fade show"
              style={{ zIndex: -1 }}
            ></div>
            {/* Modal content */}
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div
                className="modal-content"
                style={{ background: "transparent", border: "none" }}
              >
                <div className="modal-header" style={{ border: "none" }}>
                  <button
                    type="button"
                    className="btn-close bg-info"
                    aria-label="Close"
                    onClick={() => setSelectedVideo(null)}
                  ></button>
                </div>
                <div className="modal-body d-none d-md-block">
                  {/* Assuming you want to play YouTube videos */}
                  <iframe
                    width="100%"
                    height="600"
                    src={`https://www.youtube.com/embed/${selectedVideo}`}
                    title="Movie Trailer"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="modal-body d-block d-md-none">
                  {/* Assuming you want to play YouTube videos */}
                  <iframe
                    width="100%"
                    height="300"
                    src={`https://www.youtube.com/embed/${selectedVideo}`}
                    title="Movie Trailer"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="container mb-3">
        <div className="row">
          <div className="col-12 text-center">
            <Link id="_434085"
              to="https://www.toprevenuegate.com/ahfb4i6mp?key=434085b41621dba732384efa6dd9f0c5"
              className="btn btn-outline-secondary btn-lg rounded-pill"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download now
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <h3>Screenshots</h3>
          {Array.isArray(selectedImage) ? (
            <>
              {selectedImage.slice(0, 10).map((item) => (
                <div className="col-12 col-md-3">
                  <img
                    key={item.id}
                    src={fecthImage + `/${item.file_path}`}
                    alt={item.title}
                    className="w-100"
                    style={{ marginBottom: "20px" }} // Add margin-bottom between images
                  />
                </div>
              ))}
            </>
          ) : (
            <p>No Screenshots available</p>
          )}
        </div>
      </div>

      <div className="container mb-3">
      <div className="row">
        <div className="col-12 text-center">
          <Link id="_434085"
            to="//shulugoo.net/4/6758737"
            className="btn btn-outline-secondary btn-lg rounded-pill"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download now
          </Link>
        </div>
      </div>
    </div>

      <div className="container mt-5 mb-5">
        <h2>Top Rated Movies</h2>
      </div>
      <TopRated />
    </>
  );
};

export default SingleMovie;
