import React from "react";
import Hero from "../components/Hero/Hero";
import Card from "../components/Card/Card";
import { Helmet } from "react-helmet";
import Treanding from "../components/Trending/Treanding";


const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | ScreenAlley</title>
        <meta
          name="description"
          content="ScreenAlley is a popular database for movies trailer."
        />
        
      </Helmet>
      <Hero />
      <Card isHome={true} />
      <Treanding isHome={true} />
    </>
  );
};

export default Home;
