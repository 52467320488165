import React, { useEffect, useRef } from "react";

const Privcay = () => {
    const pageRef = useRef();
    useEffect(() => {
        if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    }, [])
    
  return (
    <>
    <div ref={pageRef}></div>
    <div className="container">
        <div className="row my-5">
          <h1>Privacy Policy for ScreenAlley.com</h1>

          <h5>Effective Date: 01-Dec-2023</h5>

          <p>
            At <b>ScreenAlley.com</b>, we are committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, and
            safeguard your information when you use our services, particularly
            in connection with the TMDB Developer API. By using ScreenAlley.com,
            you agree to the terms outlined in this policy.
          </p>

          <h3>Information Collection and Use</h3>

          <p>
            ScreenAlley.com interacts with the TMDB Developer API provided by
            Xperi Inc. As part of using this API, the following information may
            be collected or processed:
          </p>

          <p>
            <b>TMDB API Usage Data:</b> Information related to your interactions
            with the TMDB API.
          </p>
          <p>
            <b>Personal Information:</b> Such as your interactions with
            ScreenAlley.com, preferences, searches, and interactions with
            content.
          </p>
          <p>
            <b>Functional Data:</b> Necessary data for the proper functioning of
            ScreenAlley.com.
          </p>
          <h3>Third-Party Integration</h3>

          <p>
            <b>ScreenAlley.com</b> may integrate with the TMDB Developer API and
            other third-party applications. Your use of these integrations is
            subject to their respective terms and privacy policies. We are not
            responsible for the behavior or content of third-party applications.
          </p>

          <h3>Information Sharing</h3>

          <p>
            We may share your information with service providers to improve and
            personalize your experience on ScreenAlley.com. Additionally,
            information might be disclosed when required by law or to protect
            our rights.
          </p>

          <h3>Your Rights</h3>

          <p>
            As a user of <b>ScreenAlley.com</b>, you have the right to access,
            update, or delete your information associated with our services.
            Some data collection is necessary for the functionality of the site
            and cannot be opted out of.
          </p>

          <h3>Marketing and Communications</h3>

          <p>
            You may receive communications related to ScreenAlley.com. You can
            manage your email preferences using the provided options.
          </p>

          <h3>Cookies and Similar Technologies</h3>

          <p>
            <b>ScreenAlley.com</b> uses cookies and similar technologies to
            enhance user experience. By continuing to use the site, you consent
            to the use of these technologies. Refer to our Cookie Policy for
            more details.
          </p>

          <h3>Security Measures</h3>

          <p>
            We employ security measures to protect your information. However, we
            cannot guarantee absolute security due to factors beyond our
            control.
          </p>

          <h3>Changes to This Policy</h3>

          <p>
            We reserve the right to update this Privacy Policy. Any changes will
            be posted on this page. Please review this policy periodically.
          </p>

          <h3>Contact Us</h3>

          <p>
            If you have any questions or concerns about this Privacy Policy or
            your information, please contact us at screenalley@gmail.com.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privcay;
