import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark">
      <div className="container">
        <div className="row py-5 text-light">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="row">
              <div className="col-12 col-md-4">
                <Link to="/" className="fw-bold display-5">
                  <img
                    src="./images/footer_logo.png"
                    className="img-fluid w-50"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-12 col-md-8">
                <h4>About Us</h4>
                <p>
                  <span className="fw-bold">ScreenAlley</span> is free movie
                  trailer streaming website, it allows you watch latest movies
                  trailer online in high quality for free. This site does not
                  store any files on our server, we only linked to the media
                  which is hosted on 3rd party services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-2">
            <h4>Links</h4>
            <div className="row">
              <div className="col-12">
                <ul className="p-0" style={{listStyle: "none"}}>
                  <li>
                    <Link to="/movie" className="fw-bold text-light">
                      Movies
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="fw-bold text-light">
                      Privcay Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
