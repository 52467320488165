import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fecthImage } from "../../axios/api";
import axios from "../../axios/axios";

const Treanding = ({ isHome }) => {
  const [movies, setMovies] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`/trending/movie/day?language=en-US`)
        .then((response) => {
          setMovies((prevMovies) => [...prevMovies, ...response.data.results]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchData();
  }, [page]);

  const handleLoadMore = () => {
    setPage(page + 1); // Increment page number
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 col-12">
          <div className="row d-none">
            <div className="col-12">Ads</div>
          </div>
        </div>
        <div className="col-md-8 col-12">
          <div className="row">
            <div className="col-12">
              <div className="container my-5">
                <h1 className="mb-5">Trending</h1>
                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-3">
                  {isHome
                    ? movies.slice(0, 12).map((item, index) => (
                        <div className="col" key={index}>
                          <Link to={`/movie/${item.id}`}>
                            <div className="card">
                              <img
                                src={fecthImage + `/${item.poster_path}`}
                                className="card-img-top"
                                alt={fecthImage + `/${item.poster_path}`}
                              />
                            </div>
                          </Link>
                        </div>
                      ))
                    : movies.map((item, index) => (
                        <div className="col" key={index}>
                          <Link to={`/movie/${item.id}`}>
                            <div className="card">
                              <img
                                src={fecthImage + `/${item.poster_path}`}
                                className="card-img-top"
                                alt={fecthImage + `/${item.poster_path}`}
                              />
                            </div>
                          </Link>
                        </div>
                      ))}
                </div>
                {!isHome && (
                  <div className="text-center mt-4">
                    <button
                      onClick={handleLoadMore}
                      className="btn btn-warning"
                    >
                      Load More Movies!
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-12">
          <div className="row d-none">
            <div className="col-12">Ads</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treanding;
