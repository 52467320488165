import React, { useEffect, useState } from "react";
import axios from "../../axios/axios.js";
import { topRated, fecthImage } from "../../axios/api.js";
import "./TopRated.css";
import { Link } from "react-router-dom";

const TopRated = () => {
  const [topRatedMovies, setTopRatedMovies] = useState([]);
  // Fetch top-rated movies
  const fetchTopRatedMovies = () => {
    axios
      .get(topRated)
      .then((response) => {
        setTopRatedMovies(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching top-rated movies:", error);
      });
  };

  useEffect(() => {
    fetchTopRatedMovies();
  }, []);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="container">
            <div className="row">
              {topRatedMovies.slice(0, 6).map((topMovie) => (
                <div key={topMovie.id} className="col-md-2 mb-3">
                  <Link to={`/movie/${topMovie.id}`}>
                    <img
                      src={fecthImage + `/${topMovie.poster_path}`}
                      className="w-100"
                      alt={topMovie.title}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="row">
              {topRatedMovies.slice(6, 12).map((topMovie) => (
                <div key={topMovie.id} className="col-md-2 mb-3">
                  <Link to={`/movie/${topMovie.id}`}>
                    <img
                      src={fecthImage + `/${topMovie.poster_path}`}
                      className="w-100"
                      alt={topMovie.title}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default TopRated;
